@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;1,400;1,500&family=Roboto:ital,wght@0,400;0,500;1,400&display=swap');

* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

body {
    overflow: hidden;
}

.loader {
    background: url(https://i.postimg.cc/bNKJvqj1/space1.jpg) !important;
    overflow: hidden !important;
}

.anime-body {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    background: url(https://i.postimg.cc/bNKJvqj1/space1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .shapes {
        overflow: hidden !important;
        .rocket {
            transform: rotate(113deg);
            z-index: 100 !important;
        }
        .rocket-holder {
            animation: up-down infinite;
            animation-direction: alternate;
            animation-duration: 150ms;
            z-index: 100 !important;
            position: absolute;
            top: 50%;
            left: 30%;
        }
        .shape0 {
            position: absolute;
            background: beige;
            border-radius: 80%;
            z-index: 8;
            right: -2000px;
            animation: rocket;
            animation-iteration-count: 3;
        }
        .shape1 {
            height: 3px;
            width: 30px;
            top: 50%;
            animation-duration: 3s;
            animation-delay: 700ms;
        }
        .shape2 {
            height: 3px;
            width: 60px;
            top: 60%;
            animation-duration: 3s;
            animation-delay: 500ms;
        }
        .shape3 {
            height: 3px;
            width: 30px;
            top: 45%;
            animation-duration: 3s;
            animation-delay: 150ms;
        }
        .shape4 {
            height: 3px;
            width: 10px;
            top: 60%;
            animation-duration: 3s;
            animation-delay: 100ms;
        }
        .shape5 {
            height: 3px;
            width: 30px;
            top: 50%;
            animation-duration: 3s;
        }
    }
    .planets {
        position: absolute;
        right: -2000px;
        z-index: -1;
        animation: rocket;
        animation-iteration-count: 1;
        animation-duration: 12s;
    }
    .planet1 {
        top: 20%;
        animation-delay: 50ms;
    }
    .planet2 {
        top: 2%;
        animation-delay: 1s;
    }
    .planet4 {
        bottom: 5%;
        animation-delay: 2s;
    }
    .planet5 {
        bottom: 30%;
        animation-delay: 3s;
    }
    .planet6 {
        top: 20%;
        animation-delay: 5s;
    }
    .planet7 {
        bottom: 30%;
        animation-delay: 10s;
    }
    .planet8 {
        width: 400px;
        bottom: -30%;
        left: -1000;
        animation-duration: 25s;
        animation-delay: 1s;
    }
    .planet9 {
        bottom: 10%;
        animation-delay: 6s;
    }
    .final {
        position: absolute;
        top: 45%;
        left: 50%;
        animation: final 18s normal;
        z-index: 100;
        img {
            position: absolute;
        }
        .human {
            width: 100px;
            top: 0px;
            animation: up-down-final infinite;
            animation-direction: alternate;
            animation-duration: 1s;
        }
        .planet10 {
            animation: up-down-final infinite;
            animation-direction: alternate;
            animation-duration: 2s;
            bottom: 20%;
        }
        .planet11 {
            animation: up-down-final infinite;
            animation-direction: alternate;
            animation-duration: 2s;
            animation-delay: 2s;
            top: 10%;
        }
    }
}

@keyframes up-down {
    100% {
        transform: translateY(0px);
    }

    0% {
        transform: translateY(2px);
    }
}

@keyframes rocket {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(-4000px);
    }
}

@keyframes up-down-final {
    100% {
        transform: translateY(0px);
    }

    0% {
        transform: translateY(10px);
    }
}

@keyframes final {
    0% {
        transform: translateX(5000px);
    }

    100% {
        transform: translateX(0px);
    }
}

.text-overlay {
    position: absolute;
    margin: 40px auto;
    padding: 20px 40px;
    font-size: 25px;
    background-color: rgb(255 35 96 / 70%);
    top: calc(20% - 20px);
    left: 5%;
    z-index: 2;
    text-align: center;
    color: #fbf6ec;
    font-weight: 500;
    border-radius: 100px 0;
    font-family: 'Raleway', sans-serif;
    line-height: 30px;

    @media only screen and (max-width: 767px) {
        right: 5%;
        text-align: left;
    }

    p {
        margin: 0;

        span {
            font-size: 30px;
            font-style: italic;
        }
    }
}
.font-size-18{
    font-size: 18px;
}